import { Button } from "@mui/material";

import Layout from 'lib/components/Layout';
import { logAnalyticsEvent } from 'lib/firebase/analytics';

import { base } from 'themes';
import { Logo } from 'components/icons';
import { FOOTER_PROPS } from 'constants';
import Hero from "./Hero";

const Home = () => (
  <Layout
    theme={base}
    footerProps={FOOTER_PROPS}
    navbarProps={{
      logo: (<Logo />),
      right: (
        <Button
          href="https://szk4u.mjt.lu/wgt/szk4u/xu59/form?c=0799e44b"
          target="_blank"
          variant="contained"
          onClick={() => logAnalyticsEvent('click', { label: 'Try TCGist' })}
        >
          Try TCGist
        </Button>
      )
    }}
  >
    <Hero />
  </Layout>
);

export default Home;
